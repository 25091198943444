//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  loaderToShow: any;
  isLoading = false;
  constructor(
    private router: Router,
    private ngxUiLoader: NgxUiLoaderService,
    private translate: TranslateService
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentLang = this.translate.currentLang;
    var token = null;
    if(localStorage.getItem('authToken')) {
      var authToken = localStorage.getItem('authToken');
      token = authToken;
    }

    let headers = request.headers
    .set('Accept', 'application/json')
    .set('Content-Type', 'application/json');

    if (currentLang) {
      headers = headers.set('language', currentLang);
    }

    request = request.clone({ headers });

    if (request.url.indexOf("/articulos/crear") > 0 ) {
      request = request.clone({ headers: request.headers.delete('Content-Type','application/json') });
    }

    if (request.url.indexOf("/editar/all") > 0 ) {
      request = request.clone({ headers: request.headers.delete('Content-Type','application/json') });
    }

    if (request.url.indexOf("/usuarios/editar") > 0 ) {
      request = request.clone({ headers: request.headers.delete('Content-Type','application/json') });
    }
    if (request.url.indexOf("/importar") > 0 ) {
      request = request.clone({ headers: request.headers.delete('Content-Type','application/json') });
    }

    //Authentication by setting header with token value
    if (token) {
      request = request.clone({setHeaders: {'Authorization': 'Token ' + token}});
    }

  

    this.ngxUiLoader.start();
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        this.ngxUiLoader.stop();
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('INTERCEPTOR ERROR', error.status , error);
        if(error.status == 401) {
          localStorage.removeItem('authToken');
          localStorage.clear();
          this.router.navigate(['/auth/login']);
        }
        this.ngxUiLoader.stop();
        return throwError(error);
      }));
  }

}