


export function getMensaje(key): any {
   var lng =  localStorage.getItem("lng_sigma");

   var mensajes = {
    "es":{
        "error":"Tuvimos un error inesperado. Inténtalo de nuevo.",
        "error_credencial":"Credenciales incorrectas",
        "error_importar":"Ocurrió un error al importar los datos.",
        "success_importar":"Datos importados con éxito.",
        "missing_value":"Completar todos los campos.",
        "success":"Operación realizada con éxito",
        "email_not_exist":"No existe un usuario con ese correo electrónico",
        "password_short":"La contraseña es muy corta. Debe contener al menos 8 caracteres y combinaciones de letras y números.",
        "url_not_valid":"La url ya no es válida, vuelva a la aplicación a realizar el pedido de restablecimiento.",
        "date_compliance_required":"La fecha del último cumplimiento es obligatoria",
        "format_date_invalid":"Ingresar una fecha válida en el formato indicado.",
        "frecuency_required":"Carga al menos una frecuencia",
        "manufacturer_not_found":"No se encuentra ese fabricante seleccionado",
        "date_future_invalid":"La fecha ingresada no puede ser una fecha del futuro",
        "model_required_import":"Selecciona un modelo antes de Importar datos",
        "pass_not_match":"Las contraseñas no coinciden",
        "total_hour_exceed":"La cantidad de horas no pueden ser mayor a las horas totales.",
        "total_cicle_exceed":"La cantidad de ciclos no pueden ser mayor a los ciclos totales.",
        "edit_enable":"Habilitado para editar.",
        "tab_fan_disable":"El tab Helices está deshabilitado porque se seleccionó un motor turbofan."
    },
    "en":{
        "error":"An unexpected error occurred. Please try again.",
        "error_credencial":"Incorrect credentials.",
        "error_importar":"Issue encountered while importing data.",
        "success_importar":"Data imported successfully.",
        "missing_value":"Please complete all fields.",
        "success":"Operation completed successfully.",
        "email_not_exist":"There is no user with that email address.",
        "password_short":"The password is too short. It must be at least 8 characters long and include a combination of letters and numbers.",
        "url_not_valid":"The URL is no longer valid. Please return to the application and submit a new password reset request.",
        "date_compliance_required":"The date of the last compliance is required.",
        "format_date_invalid":"Please enter a valid date in the specified format.",
        "frecuency_required":"Enter at least one frequency.",
        "manufacturer_not_found":"The selected manufacturer cannot be found.",
        "date_future_invalid":"The entered date cannot be a future date.",
        "model_required_import":"Please select a model before importing data.",
        "pass_not_match":"The passwords do not match.",
        "total_hour_exceed":"The number of hours cannot exceed the total hours.",
        "total_cicle_exceed":"The number of cycles cannot exceed the total cycles.",
        "edit_enable":"Enabled for editing.",
        "tab_fan_disable":"The Propellers tab is disabled because a turbofan engine was selected."
    }
   }

   return mensajes[lng][key];

}

export function esPropietario(): boolean {
    if (localStorage.getItem("usuario")) {
        var user = JSON.parse(localStorage.getItem("usuario"));
        return +user.rol == 1;
    }
}


export function esTaller(): boolean {
    if (localStorage.getItem("usuario")) {
        var user = JSON.parse(localStorage.getItem("usuario"));
        return +user.rol == 2;
    }
}

export function esAnalista(): boolean {
    if (localStorage.getItem("usuario")) {
        var user = JSON.parse(localStorage.getItem("usuario"));
        return +user.rol == 3;
    }
}

export function checkVencido(componente: any): boolean {
    return componente.vencido;
}

export function checkEmpty(val: any): boolean {
    if (val != null) {
        return val.trim() == "";
    } else {
        return true;
    }
}