import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authGuard/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule)
    //canActivate: [ AuthGuard ],
    //data: { authGuard: { redirect: '/administracion' }}
  },
  {
    path: 'administracion',
    loadChildren: () => import('./administracion/administracion.module').then( m => m.AdministracionModule),
    //canActivate: [ AuthGuard ],
    //data: { authGuard: { redirect: '/administracion' }}
  },
  {
    path: 'aeronaves',
    loadChildren: () => import('./aeronaves/aeronaves.module').then( m => m.AeronavesModule),
    canActivate: [ AuthGuard ],
    data: { authGuard: { redirect: '/aeronaves' }}
  },
  {
    path: 'usuarios',
    loadChildren: () => import('./propietarios/propietarios.module').then( m => m.PropietariosModule),
    canActivate: [ AuthGuard ],
    data: { authGuard: { redirect: '/propietarios' }}
  },
  {
    path: 'biblioteca',
    loadChildren: () => import('./biblioteca/biblioteca.module').then( m => m.BibliotecaModule),
    canActivate: [ AuthGuard ],
    data: { authGuard: { redirect: '/biblioteca' }}
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
