import { Component } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';
import { esAnalista, esPropietario } from 'src/config/constants';
import { LanguageService } from './services/lng/language.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  route: string;
  usuario: any = {};
  esPropietario: any = esPropietario();
  title: any = esAnalista() ? "Usuarios" : "Propietarios";
  collapse: any = false;
  private idiomaSubscription?: Subscription;
  lng: any = null;
  constructor(public router: Router, private ngxService: NgxUiLoaderService, private toastr: ToastrService, private translate: TranslateService,private LanguageService:LanguageService) {

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          break;
        }

        case event instanceof NavigationEnd: {
          this.esPropietario = esPropietario();
          this.title = esAnalista() ? "Usuarios" : "Propietarios";
          this.usuario = JSON.parse(localStorage.getItem('usuario'));

          if(!localStorage.getItem("lng_sigma")){
            localStorage.setItem("lng_sigma","es");
          }else{
            this.switchLanguage(localStorage.getItem("lng_sigma"));
          }
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          window.scroll(0, 0);
          break;
        }
        default: {
          break;
        }
      }
    });

  }

  ngAfterViewInit() {
    this.translate.setDefaultLang('es');
  }

  getIdioma() {
    let currentLang = this.translate.currentLang; 
    if (currentLang == "en") {
      return "English";
    } else {
      return "Español";
    }
  }

  switchLanguage(language: string) {
    this.LanguageService.changeLanguage(language)
    this.translate.use(language);
  }

  ngOnInit() {
  }

  onRouteActivate(event) {
    window.scroll(0, 0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0);
  }

  logout() {
    this.router.navigateByUrl('/login');
    localStorage.clear();
  }
}


