import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID } from '@angular/core';
import { HttpConfigInterceptor } from '../config/httpConfig.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// IMPORTACION DE DEPENDECIAS SIGMA 

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderHttpModule
} from "ngx-ui-loader";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { InputMaskModule } from '@ngneat/input-mask';
import { MomentModule } from 'ngx-moment';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DynamicTranslatePipe } from './pipe/dynamic-translate.pipe';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#008b92",
  "bgsOpacity": 0.8,
  "bgsPosition": "center-center",
  "bgsSize": 60,
  "bgsType": "three-bounce",
  "blur": 11,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#008b92",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "three-bounce",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
}

const toastConfig = {
  timeOut: 5000,
  positionClass: 'toast-top-center',
  preventDuplicates: true,
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DynamicTranslatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(toastConfig),
    NgxMaskModule.forRoot(),
    InputMaskModule,
    MomentModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'en-US' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
