<ngx-ui-loader>
</ngx-ui-loader>

<nav class="navbar navbar-expand-lg navbar-dark fixed-top" *ngIf="!router.url.includes('/login')">
    <a class="navbar-brand logo" routerLink="/administracion">
        <img src="assets/images/logos/logo-clear.png" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" (click)="collapse=!collapse"
        href="#navbarSupportedContent" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngClass]="{'show':collapse}">
        <ul class="navbar-nav navtabs mr-auto">
            <li class="nav-item" routerLink="administracion" [routerLinkActive]="['active']">
                <a class="nav-link">{{'header-inicio'| translate }} <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item" routerLink="aeronaves" [routerLinkActive]="['active']">
                <a class="nav-link">{{'header-aeronaves'| translate }}</a>
            </li>
            <li class="nav-item" routerLink="usuarios" *ngIf="!esPropietario" [routerLinkActive]="['active']">
                <a class="nav-link">{{'header-usuarios' | translate}}</a>
            </li>
            <li class="nav-item" routerLink="biblioteca" *ngIf="!esPropietario" [routerLinkActive]="['active']">
                <a class="nav-link">{{'header-biblioteca'| translate }}</a>
            </li>
            <li class="nav-item" routerLink="administracion/terminologia" [routerLinkActive]="['active']">
                <a class="nav-link">{{'header-vocabulario'| translate }}</a>
            </li>
        </ul>
        <ul class="navbar-nav ">
            <li class="nav-item">
                <!-- <a class="nav-link">
                    <i class="las la-bell" style="font-size: 1.4rem;"></i>
                </a> -->
            </li>
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-link-light" id="dropdownLng" ngbDropdownToggle><span
                        [innerText]="getIdioma()"></span></button>
                <div ngbDropdownMenu aria-labelledby="dropdownLng">
                    <button ngbDropdownItem (click)="switchLanguage('es')">Español</button>
                    <button ngbDropdownItem (click)="switchLanguage('en')">English</button>
                </div>
            </div>
            <div ngbDropdown class="d-inline-block" *ngIf="usuario">
                <button class="btn btn-link-light" id="dropdownUsuario"
                    ngbDropdownToggle>{{usuario.first_name}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownUsuario">
                    <button ngbDropdownItem (click)="logout()">{{'cerrarsesion'| translate }}</button>
                </div>
            </div>
        </ul>
    </div>
</nav>

<router-outlet (activate)="onRouteActivate($event)"></router-outlet>