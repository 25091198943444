import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private currentLanguage = new BehaviorSubject<string>('es');
  currentLanguage$ = this.currentLanguage.asObservable();

  constructor() { }

  changeLanguage(language: string): void {
    localStorage.setItem("lng_sigma",language);
    this.currentLanguage.next(language);
  }
}